document.querySelectorAll(".password-shower")?.forEach(item => {
  item?.addEventListener("click", () => {
    item?.firstElementChild?.classList.toggle("fa-eye-slash")
    item?.previousElementSibling.setAttribute("type", `${item?.previousElementSibling.getAttribute("type") === "text" ? 'password' : 'text'}`)
  })
})

window.addEventListener("click", (event) => {
  if (event.target.closest("#login-dropdown") || event.target.closest(".login-dropdown")) {
    document.querySelectorAll(".element-login").forEach(item => {
      if (window.getComputedStyle(item.previousElementSibling).display !== "none") {
        item?.classList.add("active")
      }
    })
  } else {
    if (!event.target.closest(".element-login")) {
      document.querySelectorAll(".element-login").forEach(item => {
        if (window.getComputedStyle(item.previousElementSibling).display !== "none") {
          item?.classList.remove("active")
        }
      })
    }
  }
})

document.querySelector(".dashboard-wrapper_header__btn")?.addEventListener("click", (event) => {
  if (event.target.closest(".dashboard-wrapper_header__btn")) {
    document.querySelector(".dashboard-wrapper_header__btn").classList.toggle("opened")
    document.querySelector(".dashboard-wrapper_aside").classList.toggle("opened")
  }
})

const btnChange = document.getElementById("btn-change")

btnChange?.addEventListener("click", () => {
  document.querySelectorAll(".change-form").forEach(item => {
    item.classList.toggle("text-8693af")
  })
  
  document.querySelectorAll(".change-form-input")?.forEach(item => {
    item.classList.toggle("d-none")
    item.previousElementSibling.classList.toggle("d-none")
  })
  
  btnChange.classList.toggle("change-form-b")
  btnChange.nextElementSibling.classList.toggle("d-none")
  btnChange.textContent = btnChange.textContent === "Отмена" ? "Редактировать" : "Отмена"
})

document.querySelectorAll(".currency")?.forEach((item, number, list) => {
  let indexActive = 1
  item.setAttribute("data-index", number)
  if (parseInt(item.getAttribute("data-index")) === 0) {
    item.classList.add("active")
  }
  setInterval(() => {
    if (indexActive === parseInt(item.getAttribute("data-index"))) {
      item.classList.add("active")
    } else {
      item.classList.remove("active")
    }
    if (indexActive === (list.length - 1)) {
      indexActive = 0
    } else {
      indexActive += 1
    }
  }, 3000)
})


var uiLoading = function () {
  let l = document.getElementById('message');
  if (l) {
    l.innerHTML = 'Загрузка ...';
    l.style.color = 'red';
  }
}

var uiNotLoaded = function (e) {
  var l = document.getElementById('message');
  if (l) {
    l.innerHTML = '';
  }
  if (e) {
    wsError(e);
  } else {
    alert(errorBrowserWS);
  }
}

var uiUpdateApp = function () {
  var l = document.getElementById('message');
  l.innerHTML = errorUpdateApp;
}

var uiAppLoad = function () {
  uiClearCombo();
  EIMZOClient.listAllUserKeys(function (o, i) {
    var itemId = "itm-" + o.serialNumber + "-" + i;
    return itemId;
  }, function (itemId, v) {
    return uiCreateItem(itemId, v);
  }, function (items, firstId) {
    uiFillCombo(items);
    uiLoaded();
    uiComboSelect(firstId);
  }, function (e, r) {
    if (e) {
      alert(errorCAPIWS + " : " + e);
    } else {
      console.log(r);
    }
  });
  EIMZOClient.idCardIsPLuggedIn(function (yes) {
    document.getElementById('plugged').innerHTML = yes ? 'подключена' : 'не подключена';
  }, function (e, r) {
    if (e) {
      alert(errorCAPIWS + " : " + e);
    } else {
      console.log(r);
    }
  })
}

var uiComboSelect = function (itm) {
  if (itm) {
    var id = document.getElementById(itm);
    id.setAttribute('selected', 'true');
  }
}

var uiClearCombo = function () {
  var combo = document.querySelector(".list-keys")
  if (combo) {
    combo.length = 0;
  }
}

var uiFillCombo = function (items) {
  var combo = document.querySelector(".list-keys");
  if (combo) {
    for (var itm in items) {
      combo.append(items[itm]);
    }
  }
}

var uiLoaded = function () {
  var l = document.getElementById('message');
  if (l) {
    l.innerHTML = '';
  }
}

var uiCreateItem = function (itmkey, vo) {
  var now = new Date();
  vo.expired = dates.compare(now, vo.validTo) > 0;
  let itm = document.createElement("option");
  let eDiv = document.createElement("div");
  let eInput = document.createElement("input");
  let eLabel = document.createElement("label");
  let eLabelI = document.createElement("p");
  let eLabelO = document.createElement("p");
  let eLabelD = document.createElement("p");
  eDiv.setAttribute("data-index", itmkey?.split("-")?.[2])
  eDiv.setAttribute("class", "list-keys_item")
  eInput.setAttribute("id", "radio" + itmkey?.split("-")?.[2])
  eInput.setAttribute("name", "radio")
  eInput.setAttribute("type", "radio")
  eInput.setAttribute("required", "true")
  eInput.setAttribute("type", "radio")
  eInput.setAttribute('vo', JSON.stringify(vo));
  eInput.value = itmkey?.split("-")?.[2]
  eLabel.setAttribute("for", "radio" + itmkey?.split("-")?.[2])
  eLabel.setAttribute("class", "list-keys_item__l")
  eInput.setAttribute("class", "list-keys_item__i")
  eLabelI.setAttribute("class", "fs-18 fw-600 mb-1")
  eLabelI.append("ИНН: " + vo?.TIN)
  
  eLabelO.setAttribute("class", "fs-14 fw-300 mb-0 w-90")
  eLabelO.append("Организация: " + (vo?.O !== "" ? vo?.O : vo?.CN))
  
  eLabelD.setAttribute("class", "fs-14 fw-300 mb-0 w-90")
  eLabelD.append("Срок действия: " + vo?.validTo.toLocaleString())
  
  eLabel.append(eLabelI)
  eLabel.append(eLabelO)
  eLabel.append(eLabelD)
  eDiv.appendChild(eInput)
  eDiv.appendChild(eLabel)
  itm.value = itmkey;
  itm.text = vo.CN;
  
  if (!vo.expired) {
  } else {
    eInput.setAttribute("disabled", "disabled")
    eLabel.classList.add("danger")
  }
  itm.setAttribute('id', itmkey);
  return eDiv;
}

var uiShowProgress = function (r) {
  var l = document.getElementById('progress');
  if (l) {
    l.innerHTML = r ? 'Tanlanmagan' : 'Идет подписание, ждите.';
    l.style.color = r ? 'red' : 'green';
  }
};

var uiHideProgress = function () {
  var l = document.getElementById('progress');
  if (l) {
    l.innerHTML = '';
  }
};

const signin = function () {
  uiShowProgress();
  let form = document.getElementById("form-eKey");
  if (!form.elements["radio"].value) {
    uiShowProgress(true);
  } else {
    getChallenge(function (challenge) {
      if (form.elements["radio"].value) {
        var id = document.getElementById(`radio${form.elements["radio"].value}`);
        var vo = JSON.parse(id.getAttribute('vo'));
        EIMZOClient.loadKey(vo, function (id) {
          var keyId = id;
          auth(keyId, challenge, function (redirect) {
            window.location.href = redirect;
            uiShowProgress();
          });
          
        }, uiHandleError);
      } else {
        uiHideProgress();
      }
    });
  }
};

const getChallenge = function (callback) {
  microAjax('https://api-ekspertiza.mc.uz/api/auth/e-imzo-challenge', function (data, s) {
    uiHideProgress();
    if (s.status != 200) {
      var data = JSON.parse(s.response);
      alert(data.error);
      return;
    }
    try {
      var data = JSON.parse(data).result.data;
      callback(data.challenge);
    } catch (e) {
      alert(s.status + " - " + s.statusText + ": " + e);
    }
  });
}

const auth = function (keyId, challenge, callback) {
  EIMZOClient.createPkcs7(keyId, challenge, null, function (pkcs7) {
    microAjax('https://api-ekspertiza.mc.uz/api/auth/eimzo-login',
      function (data, s) {
        if (s.status != 201) {
          var data = JSON.parse(data)
          alert(s.status + " - " + data.error);
          return;
        }
        try {
          var data = JSON.parse(data).result.data;
          console.log(data)
          alert('user is authorized successfully')
          
        } catch (e) {
          alert(s.status + " - " + s.statusText + ": " + e);
        }
        
      }, 'keyId=' + encodeURIComponent(keyId) + '&pkcs7=' + encodeURIComponent(pkcs7) + '&role=' + encodeURIComponent(2));
  }, uiHandleError, false);
}

window.onload = AppLoad;



